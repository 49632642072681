<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑医废类型':'新增医废类型'" :visible.sync="show" width="500px" class="selfInputBox">

        <el-form ref="form" label-width="80px">

            <el-form-item label="医废类型名称" prop="">
                <el-input v-model="upDatavalue.name" />
            </el-form-item>



            <el-form-item label="医废类型图片" prop="">

                <!--  <el-upload drag class="SelfUploadBox" action :auto-upload="false" list-type="picture-card"
                    :on-preview="handlePreview" :on-remove="handlePreview" :before-remove="beforeRemove"
                    :on-change="uploadSectionFile" :limit="1" ref="businessLicense">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
                </el-upload> -->

                <el-upload drag class="SelfUploadBox" action="/api/item/uploadImage" :headers="Headers" name="image"
                    list-type="picture-card" :limit="1" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                    :before-upload="beforeAvatarUpload" :file-list="photoList" :show-file-list="true">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
                </el-upload>

            </el-form-item>



            <!-- 
            <el-form-item label="不需要该类型的社区医院ID" prop="">
                <el-input v-model="upDatavalue.dep_no_need" />
            </el-form-item> -->



            <el-form-item label="状态" prop="">
                <el-switch v-model="upDatavalue.status" :active-value="1" active-color="#5BD995" active-text="正常"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                </el-switch>
            </el-form-item>





            <el-form-item label="备注" prop="">
                <el-input v-model="upDatavalue.note" />
            </el-form-item>





            <el-form-item label="排序" prop="">
                <el-input v-model="upDatavalue.sort" />
            </el-form-item>



            <!--  <el-form-item label="有效标志" prop="">
                <el-input v-model="upDatavalue.mark" />
            </el-form-item> -->




        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            // value: {
            //     type: Object,
            //     required: true
            // }
        },
        data() {
            return {
                show: false,

                // 回显图片的路径
                photoList: [],
                // 图片的路径
                dialogImageUrl: "",

                upDatavalue: {
                    status: 1
                },

                isEdit: false
            }
        },


        watch: {

            show(value) {
                if (this.show) {

                } else {

                    this.isEdit = false

                    this.upDatavalue = {
                        status: 1
                    }

                    this.photoList = []
                    this.dialogImageUrl = ""

                }
            },

        },

        computed: {

            Headers() {
                let token = localStorage.getItem("token");
                return {
                    token
                }
            }

        },

        methods: {
            open() {

                this.show = true
            },

            edit(val) {

                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", val);

                this.show = true

                this.isEdit = true

                this.upDatavalue = {
                    ...val
                }

                this.dialogImageUrl = this.upDatavalue.image

                this.photoList = [{
                    url: this.GLOBAL.BASE_URL + this.upDatavalue.image
                }]

            },


            save() {

                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
                console.log(this.upDatavalue);
                console.log(this.dialogImageUrl);

                // // 回显图片的路径
                // photoList: [{
                //     url: ""
                // }],
                // // 图片的路径
                // dialogImageUrl: "",


                if (this.upDatavalue.id) {

                    // 更新

                    // this.upDatavalue.image = this.dialogImageUrl

                    var a = {
                        name: this.upDatavalue.name,
                        image: this.dialogImageUrl,
                        // dep_no_need: this.upDatavalue.dep_no_need,
                        status: this.upDatavalue.status,
                        note: this.upDatavalue.note,
                        sort: this.upDatavalue.sort,
                    }

                    this.$http.put('/api/item/' + this.upDatavalue.id, a).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                        if (res.code == 200) {

                            this.show = false
                            this.$emit('refresh')

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                } else {

                    this.upDatavalue.image = this.dialogImageUrl

                    // 新建
                    this.$http.post('/api/item', this.upDatavalue).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code == 200) {

                            this.show = false
                            this.$emit('refresh')

                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                }

            },









            handleRemove(file, fileList) {
                console.log(file, fileList)
                this.dialogImageUrl = ''
            },
            handleAvatarSuccess(res, file) {

                console.log("res, fileres, fileres, fileres, file", res, file);

                this.dialogImageUrl = res.data
                console.log(this.dialogImageUrl)
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2

                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!')
                }
                return /* isJPG && */ isLt2M
            },






        }
    }
</script>